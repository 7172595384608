/**
 * Manage line blocks (line moving on hover over a line)
 */
export const lineBlock = () => {
    let $lineBlock = jQuery('.line-block');

    if ($lineBlock.length) {
        $lineBlock.each(function () {
           let _this = jQuery(this),
               $element = _this.find('li'),
               $activeElement = _this.find('.is-active'),
               $line = _this.find('.line-block__line');

           // Activate the first element
           if ($activeElement.length) {
               $line.css({
                   width: $activeElement[0].getBoundingClientRect().width,
                   left: $activeElement.position().left
               });
           }

           // Move the line
           $element.on('mouseenter', function () {
               $line.css({
                   width: jQuery(this)[0].getBoundingClientRect().width,
                   left: jQuery(this).position().left
               });
           }).on('mouseleave', function () {
               if ($activeElement.length) {
                   $line.css({
                       width: $activeElement[0].getBoundingClientRect().width,
                       left: $activeElement.position().left
                   });
               }
           });
        });
    }
};