import initParallaxs from './parallaxs';
import initCarousels from './carousels';
import { lineBlock } from './components/line-block';
import './components/doubleTapToGo';
import './vendors/sticky';
import AnchorBlock from './components/anchor-block';
import Modal from './components/Modal';
import KamiFlash from 'kami-flash/dist/KamiFlash.umd';
import Tracking from './Tracking'; 


(
	function ($) {

		// comments validation.
		$(document).ready(function () {
        
			// Validate Username
			let usernameError = true;
			function validateUsername() {
			let usernameValue = $("#author").val();
			if (usernameValue.length == "") {
				$("#author").parent().removeClass("success").addClass("error");
				usernameError = false;
			} else {
				usernameError = true;
				$("#author").parent().removeClass("error").addClass("success");
			}
			}
	
			// Validate Email
			let emailError = true;
			function validateEmail() {
			const email = $("#email").val().trim();
			let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			let s = email;
			if (!regex.test(s)) {
				$("#email").parent().removeClass("success").addClass("error");
				emailError = false;
			} else {
				$("#email").parent().removeClass("error").addClass("success");
				emailError = true;
			}
			}
	
			// Validate Message
			let messageError = true;
			function validateMessage() {
			let messageValue = $("#comment").val();
			if (messageValue.length == "") {
				$("#comment").parent().removeClass("success").addClass("error");
				messageError = false;
			} else {
				messageError = true;
				$("#comment").parent().removeClass("error").addClass("success");
			}
			}
	
			// Submit button
			$(".comment-form input[type=submit]").click(function () {
			validateUsername();
			validateEmail();
			validateMessage();
		
			if (usernameError == true && emailError == true && messageError == true) {
				return true;
			} else {
				return false;
			}
			});
	
			 // define flash-message
			window.onload = function(){
				KamiFlash.initialPosition = 50;
				if (!customElements.get('flash-message')) {
					customElements.define('flash-message', KamiFlash);
				}
			}
		});
	


  
		
		$(document).on('ready', function () {
			var is_modal_show = sessionStorage.getItem('alreadyShow');
			var $joinUsModal = $('#join-us');
			if ($joinUsModal.length > 0 && is_modal_show != 'alredy shown') {
				setTimeout(() => $joinUsModal[0].open(), 20000);
				sessionStorage.setItem('alreadyShow','alredy shown');
			}
		});

		var pluses = /\+/g;

		function encode(s) {
			return config.raw ? s : encodeURIComponent(s);
		}

		function decode(s) {
			return config.raw ? s : decodeURIComponent(s);
		}

		function stringifyCookieValue(value) {
			return encode(config.json ? JSON.stringify(value) : String(value));
		}

		function parseCookieValue(s) {
			if (s.indexOf('"') === 0) {
				// This is a quoted cookie as according to RFC2068, unescape...
				s = s.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\');
			}

			try {
				// Replace server-side written pluses with spaces.
				// If we can't decode the cookie, ignore it, it's unusable.
				// If we can't parse the cookie, ignore it, it's unusable.
				s = decodeURIComponent(s.replace(pluses, ' '));
				return config.json ? JSON.parse(s) : s;
			} catch (e) {}
		}

		function read(s, converter) {
			var value = config.raw ? s : parseCookieValue(s);
			return $.isFunction(converter) ? converter(value) : value;
		}

		var config = $.cookie = function (key, value, options) {

			// Write

			if (arguments.length > 1 && !$.isFunction(value)) {
				options = $.extend({}, config.defaults, options);

				if (typeof options.expires === 'number') {
					var days = options.expires, t = options.expires = new Date();
					t.setMilliseconds(t.getMilliseconds() + days * 864e+5);
				}

				return (
					document.cookie = [
						encode(key), '=', stringifyCookieValue(value),
						options.expires ? '; expires=' + options.expires.toUTCString() : '', // use expires attribute, max-age is not supported by IE
						options.path ? '; path=' + options.path : '',
						options.domain ? '; domain=' + options.domain : '',
						options.secure ? '; secure' : ''
					].join('')
				);
			}

			// Read

			var result  = key ? undefined : {},
			    // To prevent the for loop in the first place assign an empty array
			    // in case there are no cookies at all. Also prevents odd result when
			    // calling $.cookie().
			    cookies = document.cookie ? document.cookie.split('; ') : [],
			    i       = 0,
			    l       = cookies.length;

			for (; i < l ; i++) {
				var parts  = cookies[i].split('='),
				    name   = decode(parts.shift()),
				    cookie = parts.join('=');

				if (key === name) {
					// If second argument (value) is a function it's a converter...
					result = read(cookie, value);
					break;
				}

				// Prevent storing a cookie that we couldn't decode.
				if (!key && (
					cookie = read(cookie)
				) !== undefined) {
					result[name] = cookie;
				}
			}

			return result;
		};

		config.defaults = {};

		$.removeCookie = function (key, options) {
			// Must not alter options, thus extending a fresh object...
			$.cookie(key, '', $.extend({}, options, {expires: -1}));
			return !$.cookie(key);
		};

		$(document).on('ready', function () {
			$(window).on('load resize', function () {
				lineBlock();
			});
			initParallaxs();
			initCarousels();

			// Popup Fermeture Cabinet
			if ($('.popup_closeinfos').length) {
				if (!$.cookie('PopupCabinetClosed')) {
					setTimeout(function () {
						$('.popup_closeinfos, .popup_closeinfos__overlay').fadeIn(400);
					}, 1000);
				}
			}

			$(document).on('click', '.popup_closeinfos__close', function (event) {
				$('.popup_closeinfos, .popup_closeinfos__overlay').fadeOut('400');
				var date = new Date();
				var minutes = 60;
				date.setTime(date.getTime() + (
					minutes * 60 * 1000
				));

				$.cookie(
					'PopupCabinetClosed',
					Math.floor(new Date().getTime() / 1000) + (
						                                        86400 * 7
					                                        ),
					$.extend({}, {expires: date, path: '/'})
				);
				event.preventDefault();
			});

			$('.home__most-practiced-acts__acts .swiper-slide').doubleTapToGo();
			$('.home__most-practiced-acts__acts__boxes > div').doubleTapToGo();
			$('.home__special-slider .home__special-slider__slide').doubleTapToGo();
			$('.home__non-surgical-specialities__slider__slide').doubleTapToGo();

			var $anchorBlock = $('.anchor-block');

			new AnchorBlock('.anchor-block__links li a', {
				reflow: true,
				offset: function () {
					var topSpacing = 50 + 136;
					if ($('body').hasClass('admin-bar')) {
						topSpacing += 32;
					}
					return topSpacing;
				}
			});

			if ($anchorBlock.length) {
				$('.page-wrapper').addClass('no-overflow');
				$('.parallax, .buttons-container__title').remove();
			}

			let fileUpload = function (settings = {
				'file_upload_container': jQuery('.file-upload'),
				'file_upload_text': jQuery('.file-upload__text'),
				'file_upload_input': jQuery('.file-upload__input'),
				'file_upload_default_input': jQuery('.file-upload').find('input[type="file"]')
			}) {
				jQuery(function () {
					let file_upload_container     = settings.file_upload_container,
					    file_upload_text          = settings.file_upload_text,
					    file_upload_input         = settings.file_upload_input,
					    file_upload_default_input = settings.file_upload_default_input;

					// On click on the fake input, click on the file input
					file_upload_input.on('click', function () {
						file_upload_default_input.click();
					});

					// Text of the input
					file_upload_default_input.on('change', function () {
						let text = this.files[0].name; // Default text

						if (this.files.length > 1) {
							text = `${this.files.length} fichiers`;
						}

						file_upload_text.html(text);
					});

					// Reset file upload on mail sent
					jQuery('.wpcf7').each(function () {
						jQuery(this).on('wpcf7mailsent', function (event) {
							jQuery(this).find(file_upload_input).val('');
							jQuery(this).find(file_upload_text).html('Aucun fichier choisi');
						});
					});
				});
			};

			fileUpload();

			/**
			 * Auto resize textarea
			 */
			$(function () {
				function autosize(el) {
					setTimeout(function () {
						$(el).css('height', 'auto');
						$(el).css('height', parseInt(el.scrollHeight) + 'px');
					}, 0);
				}

				// Auto resize textarea
				$('textarea.form-control').keydown(function (e) {
					let key = e.which || e.keyCode;

					if (key === 13 || key === 8) {
						autosize(this);
					}
				});
			});

			/**
			 * Responsive header
			 */
			$(function () {
				let $hamburger               = $('.responsive-header__hamburger'),
				    $hamburgerIcon           = $('.responsive-header__hamburger__icon'),
				    $responsiveMenu          = $('.responsive-header__menu'),
				    $responsiveHeaderOverlay = $('.responsive-header__overlay');

				if ($hamburger.length && $responsiveMenu.length) {
					$hamburger.on('click', function () {
						$hamburgerIcon.toggleClass('is-active');
						$responsiveMenu.slideToggle();
						$responsiveHeaderOverlay.fadeToggle();
					});
					$responsiveHeaderOverlay.on('click', function () {
						$hamburgerIcon.removeClass('is-active');
						$responsiveMenu.slideUp();
						$(this).fadeOut();
					});
				}
			});

			/**
			 * Sidebar informations
			 */
			$(function () {
				let $sidebarInformationsClick   = $('.header__informations, .responsive-header__informations'),
				    $sidebarInformations        = $('.sidebar-informations'),
				    $sidebarInformationsOverlay = $('.sidebar-informations__overlay'),
				    $sidebarInformationsClose   = $('.sidebar-informations__close, .sidebar-informations__overlay');

				if ($sidebarInformations.length) {
					$sidebarInformationsClick.on('click', function (e) {
						e.preventDefault();
						$sidebarInformations.css({
							'opacity': 1,
							'right': 0
						});
						$sidebarInformationsOverlay.fadeIn();
					});

					$sidebarInformationsClose.on('click', function (e) {
						e.preventDefault();
						$sidebarInformations.removeAttr('style');
						$sidebarInformationsOverlay.fadeOut();
					});
				}
			});

			/**
			 * Search form
			 */
			$(function () {
				let $searchForm        = $('.search-form'),
				    $searchFormInput   = $('.search-form input'),
				    $searchFormOverlay = $('.search-form__overlay'),
				    $searchFormClick   = $('.header__search, .responsive-header__search a'),
				    $searchFormClose   = $('.search-form__close, .search-form__overlay');

				if ($searchForm.length) {
					$searchFormClick.on('click', function (e) {
						e.preventDefault();
						$searchForm.addClass('is-active');
						$searchFormOverlay.fadeIn();
						$searchFormInput.focus();
					});

					$searchFormClose.on('click', function (e) {
						e.preventDefault();
						$searchForm.removeClass('is-active');
						$searchFormOverlay.fadeOut();
					});
				}
			});

			/**
			 * Sticky image
			 */
			$(function () {
				if ($('.sticky-image').length) {
					$('.sticky-image__image > div > div').stick_in_parent({
						offset_top: $('body').hasClass('admin-bar') ? 62 : 30
					});
				}
			});

			/**
			 * Buttons container
			 */
			$(function () {
				let $buttonsContainer = $('.buttons-container');

				if ($buttonsContainer.length) {
					$(window).on('load resize', function () {
						$buttonsContainer.each(function () {
							let $buttons = $(this).find('a'),
							    maxWidth, maxHeight;

							$buttons.each(function () {
								$(this).removeAttr('style');
							});

							maxWidth = Math.max.apply(Math, $buttons.map(function () {
								return $(this).outerWidth();
							}).get());

							maxHeight = Math.max.apply(Math, $buttons.map(function () {
								return $(this).outerHeight();
							}).get());

							$buttons.each(function () {
								$(this).css({
									'width': maxWidth + 5,
									'height': maxHeight + 5
								});
							});
						});
					});
				}
			});

			/**
			 * Sub menu responsive
			 */
			$(function () {
				$('.responsive-header__menu li.menu-item-has-children > a').on('click', function (e) {
					e.preventDefault();
					let parent = $(this).parent();

					parent.toggleClass('is-active');
					parent.find('.sub-menu').eq(0).slideToggle();
				});

				$('.responsive-header__menu li.menu-item-has-children > a > span').on('click', function (e) {
					e.stopPropagation();
				});
			});

			/**
			 * Header menu current item
			 */
			$(function () {
				let $headerMenu = $('.header__menu');

				if ($headerMenu.length) {
					if ($headerMenu.find(
						'.ubermenu-current-menu-item, .ubermenu-current-category-ancestor, .ubermenu-current-post-ancestor, .ubermenu-current-page-ancestor').length) {
						$headerMenu.addClass('header__menu--active');
					}
				}
			});

			/**
			 * Back to top
			 */
			$(function () {
				let $backToTop = $('.back-to-top');

				$backToTop.on('click', function () {
					$('html, body').stop().animate({scrollTop: 0}, 500);
				});

				$(window).on('load scroll', function () {
					if ($backToTop.length) {
						let scrollTop = $(window).scrollTop();

						if (scrollTop > 500) $backToTop.addClass('is-active'); else $backToTop.removeClass('is-active');
					}
				});
			});

			/**
			* 	dateForm
			 */

			
			// $(document).ready(function () {
			// 	$("#comment-form").validate({
			// 		errorClass: 'input-error',
			// 		validClass: 'input-valid',
			// 		rules: {
			// 			author : "required",
			// 		email : "required",
			// 			comment: {
			// 				required: true,
			// 				minlength: 1
			// 			}
			// 		},
			// 		messages: {
			// 		author: "Le champ n'est pas valide",
			// 		comment: "Le champ n'est pas valide",
			// 		email: "Le champ n'est pas valide",
			// 		},
			// 		errorPlacement: function(error, element) {
			// 		error.insertAfter(element.parent());
			// 		}
			// 	});
			// });

			/* Input focused */ 

			$(document).ready(function () {
				var textareas = $('.input-control textarea');
			
				function handleFocus() {
					if (!$(this).hasClass('input--focused')) {
						$(this).addClass('input--focused');
						$(this).parent().addClass('input--active');
					}
				}
			
				function handleBlur() {
					if ($(this).val() === '' && $(this).hasClass('input--focused')) {
						$(this).removeClass('input--focused');
						$(this).parent().removeClass('input--active');
					}
				}
			
				textareas.on('focus', handleFocus);
				textareas.on('blur', handleBlur);
			});
	
				
			  

			/**
			 * Bugfix uber menu tabs
			 */
			$(function () {
				$(window).on('load resize', function () {
					let $submenuMega = $('.ubermenu-has-submenu-mega');

					$submenuMega.each(function () {
						let $tabs = $(this).find('.ubermenu-tabs');

						if ($tabs.length) {
							let tabsMaxHeight = [];

							$tabs.each(function () {
								let isActive = $(this).parent().parent().hasClass('ubermenu-active');

								if (!isActive) $(this).parent().parent().addClass('ubermenu-active');

								$(this).removeAttr('style');
								$(this).find('.ubermenu-tabs-group').removeAttr('style');

								tabsMaxHeight.push(Math.max.apply(null, $(this).find('.ubermenu-tab-content-panel').map(function () {
									if ($(this).parent().hasClass('ubermenu-active')) {
										return $(this).removeAttr('style').outerHeight();
									} else {
										$(this).parent().addClass('ubermenu-active');
										let h = $(this).removeAttr('style').outerHeight();
										$(this).parent().removeClass('ubermenu-active');
										return h;
									}
								})));

								if (!isActive) $(this).parent().parent().removeClass('ubermenu-active');

							});

							tabsMaxHeight = Math.max.apply(null, tabsMaxHeight);

							$tabs.css('min-height', tabsMaxHeight);
							$(this).find('.ubermenu-tabs-group').css('min-height', tabsMaxHeight);
							$(this).find('.ubermenu-tab-content-panel').css('min-height', tabsMaxHeight);
						}
					});
				});
			});

			$(window).on('resize load scroll', function () {
				let scrollTop = $(window).scrollTop();
				let $headerWrapper = $('.header-wrapper-wrapper');
				let $header = $('.header-wrapper');

				if (scrollTop >= 50) {
					$header.addClass('is-sticky');
				} else {
					$header.removeClass('is-sticky');
				}
			});

			/**
			 * Call callback function after a certain delay
			 *
			 * @param callback
			 * @param delay
			 * @returns {function(...[*]=)}
			 */
			const debounce = (callback, delay) => {
				let timer;
				return function () {
					let args    = arguments,
					    context = this;

					clearTimeout(timer);
					timer = setTimeout(() => callback.apply(context, args), delay);
				};
			};

			$(window).on('load resize', debounce(
				() => {
					let $downloadPDF = $(document).find('.download-button'),
					    $anchorBlock = $('.anchor-block'),
					    windowSize   = $(window).width();

					if ($downloadPDF.length && $anchorBlock.length) {
						if (windowSize < 768) {
							(
								$downloadPDF
								.addClass('container')
								.css({
									'text-align': 'center',
									'max-width': '300px',
									'margin-top': '25px',
									'margin-bottom': '25px'
								})
							).insertBefore($anchorBlock);
						} else {
							if ($downloadPDF.hasClass('container')) {
								(
									$downloadPDF
									.removeAttr('style')
									.removeClass('container')
								)
								.appendTo($('.anchor-block__links__button'));
							}
						}
					}
				},
				300
			));

			$(window).on('load resize', function () {
				const $header        = $('.responsive-header'),
				      $headerWrapper = $('.responsive-header-wrapper');

				if ($header.is(':visible')) {
					document.documentElement.style.setProperty('--responsive-header-height', `${$header[0].getBoundingClientRect().height}px`);
					$headerWrapper.addClass('sticky');
				} else {
					$headerWrapper.removeClass('sticky');
					document.documentElement.style.setProperty('--responsive-header-height', 0);
				}
			});

			$(document).find('.categories-select').on('change', function () {
				const url = $('option:selected', this).attr('data-url');
				if (url) window.location = url;
				return false;
			});

			$(window).on('load resize', function () {
				const $breadcrumb            = $('.breadcrumb'),
				      $internalPageHeroImage = $('.internal-page__hero > div > div:nth-child(1)');

				if ($internalPageHeroImage.length && $breadcrumb.length) {
					let internalPageHeroImageWidth = $internalPageHeroImage[0].getBoundingClientRect().width;
					$breadcrumb.css('max-width', `${internalPageHeroImageWidth - 120}px`);
				}
			});

			// Textes de la video
			$(document).on('click', '.video__content__more__title', function (event) {
				event.preventDefault();
				$(this).parent().find('.video__content__more__text').slideToggle(400);
				$(this).toggleClass('open');
				$(this).parent('.video__content__more').toggleClass('open');
			});

		});

	}
)(jQuery);

customElements.define('modal-popup', Modal);
KamiFlash.initialPosition = 520;
customElements.define('flash-message', KamiFlash);
Tracking.init();
