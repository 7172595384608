import TrackingEvent from "./components/TrackingEvent";

export default class Tracking {

	static init() {
		window.dataLayer = window.dataLayer || [];

		TrackingEvent.Click(
			'[data-seo-doctolib]',
			{
				'event': "clic_doctolib"
			}
		)
	}
}