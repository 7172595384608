import Rellax from './vendors/rellax';

/**
 * Initialize all parallaxs
 */
export default function initParallaxs() {

    if (jQuery('.home__surgical-specialities__title').length) {
        new Rellax('.home__surgical-specialities__title', {
            speed: 2.2,
            horizontalParallax: true
        });
    }

    if (jQuery('.home__most-practiced-acts__title').length) {
        jQuery('.home__most-practiced-acts__title').css('transform', 'translateX(-40%)');
        new Rellax('.home__most-practiced-acts__title', {
            speed: -2.2,
            horizontalParallax: true
        });
    }

    if (jQuery('.home__non-surgical-specialities__text__title').length) {
        jQuery('.home__non-surgical-specialities__text__title').css('transform', 'translateX(85%');
        new Rellax('.home__non-surgical-specialities__text__title', {
            speed: 2.2,
            horizontalParallax: true
        });
    }

    if (jQuery('.home__office-and-team__title').length) {
        jQuery('.home__office-and-team__title').css('transform', 'translateX(50%');
        new Rellax('.home__office-and-team__title', {
            speed: 2.2,
            horizontalParallax: true
        });
    }

    if (jQuery('.home__videos__title').length) {
        jQuery('.home__videos__title').css('transform', 'translateX(-160%)');
        new Rellax('.home__videos__title', {
            speed: -2.2,
            horizontalParallax: true
        });
    }

    if (jQuery('.search-page__parallax').length) {
        new Rellax('.search-page__parallax', {
            speed: 2.2,
            horizontalParallax: true
        });
    }

    if (jQuery('.parallax--right-to-left').length) {
        new Rellax('.parallax--right-to-left', {
           speed: 2.2,
           horizontalParallax: true
        });
    }

    if (jQuery('.parallax--left-to-right').length) {
        new Rellax('.parallax--left-to-right', {
            speed: -2.2,
            horizontalParallax: true
        });
    }

    if (jQuery('.pathologies-block__title').length) {
        new Rellax('.pathologies-block__title', {
            speed: 2.2,
            horizontalParallax: true
        });
    }

    if (jQuery('.buttons-container__title').length) {
        new Rellax('.buttons-container__title', {
            speed: 2.2,
            horizontalParallax: true
        });
    }

};