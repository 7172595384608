import Swiper from './vendors/swiper';

/**
 * Initialize all carousel
 */
export default function initCarousels() {

    /* MOST PRACTICED ACTS CAROUSEL IN HOME */
    if (jQuery('.home__most-practiced-acts__acts__boxes').length) {
        let mostPracticedActs = undefined;
        let initMostPracticedActs = () => {
            let screenWidth = jQuery(window).width(),
                selector = '.home__most-practiced-acts__acts__boxes';

            if (screenWidth < 768 && mostPracticedActs === undefined) {
                // Create the carousel
                jQuery(selector).addClass('swiper-container');
                jQuery(selector).children('div').addClass('swiper-slide');
                jQuery(selector).wrapInner('<div class="swiper-wrapper" />');

                // Add controls to slide
                jQuery(selector).append('<div class="home__most-practiced-acts__acts__boxes__previous" />');
                jQuery(selector).append('<div class="home__most-practiced-acts__acts__boxes__next" />');

                mostPracticedActs = new Swiper(selector, {
                    slidesPerView: 2,
                    spaceBetween: 30,
                    loop: true,
                    observer: true,
                    navigation: {
                        prevEl: '.home__most-practiced-acts__acts__boxes__previous',
                        nextEl: '.home__most-practiced-acts__acts__boxes__next'
                    },
                    breakpoints: {
                        600: {
                            slidesPerView: 1,
                            spaceBetween: 0
                        }
                    }
                });
            } else if (screenWidth > 767 && mostPracticedActs !== undefined) {
                // Destroy the carousel
                mostPracticedActs.destroy();
                mostPracticedActs = undefined;
                jQuery(selector).removeClass('swiper-container');
                jQuery(selector).contents().contents().unwrap('.swiper-wrapper');
                jQuery(selector).find('.home__most-practiced-acts__acts__boxes__previous').remove();
                jQuery(selector).find('.home__most-practiced-acts__acts__boxes__next').remove();
                jQuery(selector).children('div').removeClass('swiper-slide');
            }
        };

        initMostPracticedActs();
        jQuery(window).on('resize', () => { initMostPracticedActs(); });
    }

    /* NON SURGICAL SPECIALITIES CAROUSEL IN HOME */
    if (jQuery('.home__non-surgical-specialities__slider').find('.default-carousel').length) {
        jQuery('.home__non-surgical-specialities__slider .swiper-navigations').detach().appendTo(jQuery('.home__non-surgical-specialities__slider > div > div > div').parent());
        var nonSurgicalSlider = new Swiper('.home__non-surgical-specialities__slider .default-carousel', {
            slidesPerView: 2,
            spaceBetween: 75,
            loop: true,
            observer: true,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false
            },
            keyboard: {
                enabled: true
            },
            navigation: {
                prevEl: '.home__non-surgical-specialities__slider__previous',
                nextEl: '.home__non-surgical-specialities__slider__next'
            },
            breakpoints: {
                1650: {
                    slidesPerView: 2,
                    spaceBetween: 35,
                    loop: true
                },
                1247: {
                    slidesPerView: 2,
                    spaceBetween: 35,
                    loop: true
                },
                1025: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: true
                },
                767: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: true
                },
                600: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: true
                }
            }
        });

        // var hoverSlideWidth = nonSurgicalSlider.slides[nonSurgicalSlider.activeIndex].getBoundingClientRect().height;
        var hoverSlideWidth = jQuery('.home__non-surgical-specialities__slider .home__non-surgical-specialities__slider__slide__image').innerHeight();
        jQuery('.home__non-surgical-specialities__slider .home__non-surgical-specialities__slider__slide__content').css('height', hoverSlideWidth);

        nonSurgicalSlider.on('slideChange resize', function () {
            // jQuery('.home__non-surgical-specialities__slider .home__non-surgical-specialities__slider__slide__content').css('height', this.slides[this.activeIndex].getBoundingClientRect().height);
            jQuery('.home__non-surgical-specialities__slider .home__non-surgical-specialities__slider__slide__content').css('height', jQuery('.home__non-surgical-specialities__slider .home__non-surgical-specialities__slider__slide__image').innerHeight());
        });


        jQuery('.home__non-surgical-specialities__slider .swiper-container').hover(function () {
            this.swiper.autoplay.stop();
        }, function () {
            this.swiper.autoplay.start();
        });
    }

    if (jQuery('.default-carousel.auto-carousel').length) {
        var slider = new Swiper('.default-carousel.auto-carousel', {
            slidesPerView: 1,
            loop: true,
            observer: true,
            autoHeight: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            keyboard: {
                enabled: true
            },
            navigation: {
                prevEl: '.home__non-surgical-specialities__slider__previous',
                nextEl: '.home__non-surgical-specialities__slider__next'
            }
        });

        jQuery('.default-carousel.auto-carousel').hover(function () {
            this.swiper.autoplay.stop();
        }, function () {
            this.swiper.autoplay.start();
        });

        if( jQuery('.default-carousel .swiper-slide iframe').length ) {
            var heightIframeMap = jQuery('.default-carousel .swiper-slide.wpb_single_image').innerHeight();
            jQuery('.default-carousel .swiper-slide iframe').css('height', heightIframeMap);

            slider.on('slideChange resize', function () {
                jQuery('.default-carousel .swiper-slide iframe').css('height', jQuery('.default-carousel .swiper-slide.wpb_single_image').innerHeight());
            });
        }
    }

    /* OFFICE AND TEAM CAROUSEL IN HOME */
    if (jQuery('.home__office-and-team').find('.default-carousel').length) {
        new Swiper('.home__office-and-team .default-carousel', {
           slidesPerView: 1,
           spaceBetween: 15,
           loop: true,
           observer: true,
           autoplay: true,
           pagination: {
               el: '.swiper-pagination',
               clickable: true
           },
           navigation: {
               prevEl: '.home__office-and-team__content__slider__previous',
               nextEl: '.home__office-and-team__content__slider__next'
           }
        });
    }

    /* NEWSCAROUSEL IN HOME */
    if (jQuery('.home__news').length) {
        let news = undefined;
        let initHomeNewsSlider = () => {
            let screenWidth = jQuery(window).width(),
                selector = '.home__news .news';

            if (screenWidth < 561 && news === undefined) {
                // Create the carousel
                jQuery(selector).addClass('swiper-container');
                jQuery(selector).children('article').addClass('swiper-slide');
                jQuery(selector).wrapInner('<div class="swiper-wrapper" />');

                // Add controls to slide
                jQuery(selector).prepend('<div class="home__news__navigations" />');
                jQuery('.home__news__navigations').append('<div class="home__news__previous" />');
                jQuery('.home__news__navigations').append('<div class="home__news__next" />');

                news = new Swiper(selector, {
                    slidesPerView: 1,
                    loop: true,
                    observer: true,
                    navigation: {
                        prevEl: '.home__news__previous',
                        nextEl: '.home__news__next'
                    }
                });
            } else if (screenWidth > 560 && news !== undefined) {
                // Destroy the carousel
                news.destroy();
                news = undefined;
                jQuery(selector).removeClass('swiper-container');
                jQuery(selector).contents().contents().unwrap('.swiper-wrapper');
                jQuery(selector).find('.home__news__previous').remove();
                jQuery(selector).find('.home__news__next').remove();
                jQuery(selector).children('div').removeClass('swiper-slide');
            }
        };

        initHomeNewsSlider();
        jQuery(window).on('resize', () => { initHomeNewsSlider(); });
    }

    /* HOME SPECIAL SLIDER */
    if (jQuery('.home__special-slider .default-carousel').length) {
        var homeSpecialSlider = new Swiper('.home__special-slider .default-carousel', {
           slidesPerView: 3,
           spaceBetween: 35,
           loop: true,
           observer: true,
           autoplay: {
               delay: 1500
           },
           navigation: {
               prevEl: '.home__special-slider__previous',
               nextEl: '.home__special-slider__next'
           },
           breakpoints: {
               1439: {
                   slidesPerView: 2,
                   spaceBetween: 15
               },
               767: {
                   slidesPerView: 2,
                   spaceBetween: 15
               },
               650: {
                   slidesPerView: 1.6,
                   spaceBetween: 15
               },
               500: {
                   slidesPerView: 1,
                   spaceBetween: 0
               }
           }
        });

        jQuery('.home__special-slider .swiper-container').hover(function () {
            this.swiper.autoplay.stop();
        }, function () {
            this.swiper.autoplay.start();
        });
        jQuery('.home__special-slider__previous, .home__special-slider__next').click(function () {
            this.swiper.autoplay.stop();
        });
    }

    /* CONSULTANTS SLIDER */
    if (jQuery('.consultants-kinesitherapy__slider').find('.default-carousel').length) {
        var consultantSlider = new Swiper('.consultants-kinesitherapy__slider .default-carousel', {
            slidesPerView: 3,
            spaceBetween: 30,
            loop: true,
            observer: true,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            pagination: {
                el: '.consultants-kinesitherapy__slider__pagination',
                clickable: true
            },
            navigation: {
                prevEl: '.consultants-kinesitherapy__slider__previous',
                nextEl: '.consultants-kinesitherapy__slider__next'
            },
            on: {
                slideChange: function () {
                    if (this.realIndex === 2 || this.realIndex === 0) {
                        jQuery('.consultants-kinesitherapy__slider__pagination > span:nth-child(1)').addClass('swiper-pagination-bullet-active');
                    }
                    if (this.realIndex === 3 || this.realIndex === 1) {
                        jQuery('.consultants-kinesitherapy__slider__pagination > span:nth-child(2)').addClass('swiper-pagination-bullet-active');
                    }
                }
            },
            breakpoints: {
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 15
                },
                767: {
                    slidesPerView: 1,
                    spaceBetween: 0
                }
            }
        });

        var actualSlideWidth = consultantSlider.slides[consultantSlider.activeIndex].getBoundingClientRect().width;
        document.querySelector('.consultants-kinesitherapy__slider__controls').style.width = actualSlideWidth + 'px';

        consultantSlider.on('slideChange resize', function () {
            document.querySelector('.consultants-kinesitherapy__slider__controls').style.width = this.slides[this.activeIndex].getBoundingClientRect().width + 'px';
        });

        jQuery('.consultants-kinesitherapy__slider .swiper-container').hover(function () {
            this.swiper.autoplay.stop();
        }, function () {
            this.swiper.autoplay.start();
        });
    }

    // if (jQuery('.medical-secretaries').length) {
    //     var medicalSecretariesSlider = new Swiper('.medical-secretaries .default-carousel', {
    //         slidesPerView: 2.4,
    //         spaceBetween: 40,
    //         observer: true,
    //         breakpoints: {
    //             1024: {
    //                 slidesPerView: 1.5,
    //                 spaceBetween: 20
    //             },
    //             560: {
    //                 slidesPerView: 1.2,
    //                 spaceBetween: 0
    //             }
    //         }
    //     });
    // }
};